import React from 'react';
const Horarios = () => {
    return (
        <div className="row">
            <div className="col-lg-12">                
                <div className="row align-items-end">
                    <div className="col-lg-8">
                        <div className="section-title text-left">
                            <h2>Nossos Horários</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-end">
                    <div className="col-lg-8">
                        <h3>Musculação e secretária</h3>
                        <table>
                            <tr>
                                <th>HORÁRIOS</th>
                                <th>Início</th>
                                <th>Fim</th>
                            </tr>
                            <tr>
                                <td>Segunda-feira à Sexta-feira</td>
                                <td>07:00H</td>
                                <td>22:00H</td>
                            </tr>
                            <tr>
                                <td>Sábado</td>
                                <td>08:00H</td>
                                <td>13:00H</td>
                            </tr>
                            <tr>
                                <td>Domingo</td>
                                <td>08:00H</td>
                                <td>12:00H</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="row mt--55 align-items-end">
                    <div className="col-lg-8">
                        <h3>Atividades coletivas manhã</h3>
                        <table>
                            <tr>
                                <th>Dia da semana/Horário</th>
                                <th>07:00H</th>
                                <th>08:00H</th>
                            </tr>
                            <tr>
                                <td>Segunda-feira</td>                                
                                <td>ZUMBA MIX</td> 
                                <td>JUMP</td>
                            </tr>
                            <tr>
                                <td>Terça-feira</td>
                                <td>FUNCIONAL</td>
                                <td>FIT DANCE</td>
                            </tr>
                            <tr>
                                <td>Quarta-feira</td>
                                <td>ZUMBA MIX</td>
                                <td>JUMP</td>
                            </tr>
                            <tr>
                                <td>Quinta-feira</td>
                                <td>FUNCIONAL</td>
                                <td>FIT DANCE</td>
                            </tr>
                            <tr>
                                <td>Sexta-feira</td>
                                <td>ZUMBA MIX</td>
                                <td>JUMP</td>
                            </tr>
                        </table>
                    </div>
                </div> 
                <div className="row mt--55 align-items-end">
                    <div className="col-lg-8">
                        <h3>Atividades coletivas noite</h3>
                        <table>
                            <tr>
                                <th>Dia da semana/Horário</th>
                                <th>18:00H</th>
                                <th>19:00H</th>
                                <th>20:00H</th>
                            </tr>
                            <tr>
                                <td>Segunda-feira</td>
                                <td>JUMP AVANÇADO</td>
                                <td>FIT DANCE</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Terça-feira</td>
                                <td>FUNCIONAL</td>
                                <td>ZUMBA MIX</td>
                                <td>JUMP</td>
                            </tr>
                            <tr>
                                <td>Quarta-feira</td>
                                <td>JUMP AVANÇADO</td>
                                <td>FIT DANCE</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Quinta-feira</td>
                                <td>FUNCIONAL</td>
                                <td>ZUMBA MIX</td>
                                <td>JUMP</td>
                            </tr>
                            <tr>
                                <td>Sexta-feira</td>
                                <td>JUMP AVANÇADO</td>
                                <td>FIT DANCE</td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        * Aulas de jump estão sujeitas a disponibilidade por ordem de chegada + nome na lista de presença <br />
                        * Horários das aulas coletivas sujeitos a alteração conforme disponibilidade da empresa
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Horarios
