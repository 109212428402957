import React ,{ Component }from "react";
//import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import '../../../public/assets/js/fitnessicons.js';

const ServiceList = [
    {
        //icon: <FiLayers />,
        icon: <i class="fa-solid fa-heart"></i>,
        title: 'Saúde',
        description: 'Nossos treinos e nossa academia tem como foco principal cuidar da saúde para uma melhora na qualidade de vida.'
    },
    {
        //icon: <FiLayers />,
        icon: <i class="fa-solid fa-bicycle"></i>,
        title: 'Aeróbico',
        description: 'Temos esteiras, bicicletas ergômetricas e transports para ajudar a melhorar o sistema cardiorespirátorio.'
    },
    {
        //icon: <FiUsers />,
        icon: <FiUsers />,
        title: 'Aulas coletivas',
        description: 'Para sair da mesmice temos Jump, Zumba, Fit dance e funcional para quem quer algo a mais.'
    },
    { 
        //icon: <FiMonitor />,
        icon: <i class="fa-solid fa-dumbbell"></i>,
        title: 'Vasta aparelhagem de musculação',
        description: 'Se seu foco é hipertrofia temos aparelhos novos e modernos para te ajudar a ganhar aquela definição.'
    },
    {
        //icon: <FiUsers />,
        icon: <i class="fa-solid fa-ranking-star"></i>,
        title: 'Equipe preparada',
        description: 'Temos um time diversificado e mais que qualificado e preparado para cada tipo de objetivo.'
    }//,
    // { 
    //     //icon: <FiMonitor />,
    //     icon: <i class="fa-solid fa-dumbbell"></i>,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#service">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
